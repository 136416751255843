<template>
	<PageLayout :title="$t('deposit.title')">
		<div v-if="!loading" class="cashier-box cashier-box--deposit">
			<form id="gatewayRedir" action="#" method="post">
				<input type="hidden" name="version" value="1.0" />
				<input id="encodedMessage" type="hidden" name="encodedMessage" />
				<input id="signature" type="hidden" name="signature" />
			</form>
			<div>
				<amount-buttons
					:buttons-value="predefinedDepositAmounts"
					:form-amount-value="form.amount || predefinedDepositAmounts[0]"
					:currency="'USD'"
					:form-errors="formErrors['amount']"
					@set-button-value="setButtonValue"
				/>

				<button type="button" class="button primary-button deposit center" @click="pay()">
					{{ $t('deposit.top-up') }}
				</button>

				<div v-if="loading" class="loader-wrapper">
					<loader />
				</div>
			</div>
		</div>
	</PageLayout>
</template>
<script>
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DemoDeposit',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		amountButtons,
		paymentWallet,
	},
	data() {
		return {
			form: {
				amount: null,
			},
			formErrors: {
				amount: null,
			},
			predefinedDepositAmounts: [10, 20, 50, 100],
			loading: false,
			paymentDone: false,
		};
	},
	methods: {
		async createTransaction(walletId, amount, currency) {
			const response = await apiClient.topUpDemoAccount(walletId, amount, currency);
			if (response.status !== 200) {
				throw new Error('Failed to top up demo account');
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		async pay() {
			this.loading = true;
			const walletId = this.$route.params.walletId;
			try {
				await this.createTransaction(walletId, this.form.amount, 'USD');
				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'success' },
				});
			} catch (e) {
				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'error' },
				});
				console.error('Failed to create monopoly transaction', e);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
<style scoped>
#gatewayRedir {
	visibility: hidden;
}

.no-mr-top {
	margin-top: 0px;
}
</style>
